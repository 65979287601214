@import "../../../../scss/variables";

.DealerStepReportV3 {
  padding: 0 20px;

  .DealerStepReport-title {
    font-weight: $recon-medium;
    font-size: 24px;
    line-height: 28px;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .DealerStepReport-Overview {
    display: flex;
    flex-direction: column;
    width: 100%;

    .DealerStepReport-Overview-Items {
      display: flex;
    }
  }

  .DealerStepReport-StepSummary {
    display: flex;
    flex-direction: column;
    width: 100%;

    .DealerStepReport-StepSummary-table {
      --border-radius: 5px;

      font-size: 22px;
      line-height: 20px;
      letter-spacing: 0.25px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
      border-radius: var(--border-radius);
    }

    .StickyContainer {
      border-radius: var(--border-radius) var(--border-radius) 0 0;

      .DealerStepReport-StepSummary-summary-row-header {
        transition: border-radius 100ms;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
      }

      &.stuck .DealerStepReport-StepSummary-summary-row-header {
        border-radius: 0;
      }
    }

    .DealerStepReport-StepSummary-summary-row-header {
      padding: 15px 36px;
      display: flex;
      width: 100%;
      background-color: #a9a9a9;
      color: $recon-white;
      font-weight: $recon-medium;

      .evenly-spaced-container {
        width: 100%;
        gap: $spacing-lg;
      }

      .evenly-spaced-item {
        display: flex;
        align-items: center;
        justify-content: center;

        &.step {
          justify-content: flex-start;
        }
      }
    }

    .DealerStepReport-StepSummary-summary-body {
      border-radius: 0 0 var(--border-radius) var(--border-radius);

      > :nth-child(odd) {
        background-color: $recon-white;
      }

      > :nth-child(even) {
        background-color: #e4e4e4;
      }

      .StepAccordion > button {
        all: unset;
        display: block;
        width: 100%;
        cursor: pointer;
      }

      .DealerStepReport-StepSummary-summary-row {
        display: flex;
        align-items: center;
        min-height: 50px;
        padding: $spacing-sm $spacing-md;
        box-sizing: border-box;
        font-weight: 400;
        border-bottom: 1px solid #e4e4e4;

        .ArrowIcon {
          transition: transform 250ms;
          color: rgba(0, 0, 0, 0.54);
        }
        &.AccordionOpen .ArrowIcon {
          transform: rotate(90deg);
        }

        .DealerStepReport-StepSummary-stepWrapper {
          display: flex;
          padding: $spacing-sm;
          padding-left: $spacing-sm + $spacing-slg;
        }
        &.HasArrow .DealerStepReport-StepSummary-stepWrapper {
          padding-left: $spacing-sm;
        }

        .DealerStepReport-StepSummary-columnsWrapper {
          align-items: center;
        }

        .DealerStepReport-StepSummary-vehicles {
          text-decoration: underline;
        }

        .DealerStepReport-StepSummary-AvgRetailReadyTime,
        .DealerStepReport-StepSummary-AvgReconTime {
          &.withGoal {
            font-size: 18px;
          }

          &.early {
            color: #1ab245;
          }

          &.overdue {
            color: #d50000;
          }

          + .goal {
            display: block;
            font-size: 16px;
            color: #808080;
          }
        }
      }

      .DealerStepReport-StepSummary-summary-body-ChildSteps {
        font-size: 18px;

        > :nth-child(odd) {
          background-color: $recon-white;

          &:last-child {
            border-bottom: 1px solid #e4e4e4;
          }
        }

        > :nth-child(even) {
          background-color: #f3f3f3;
        }

        .DealerStepReport-StepSummary-summary-row {
          border-bottom: none;
          min-height: 35px;

          .DealerStepReport-StepSummary-AvgRetailReadyTime,
          .DealerStepReport-StepSummary-AvgReconTime {
            + .goal {
              display: inline;
            }
          }
        }
      }
    }
  }
}
