@import "scss/variables";
@import "scss/theme/variables.scss";

.invoicing {
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: $light;

  .nav-menu {
    border-right: 1px solid #eee;
  }

  .new-invoice {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
  }

  .paidInvoice {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
  }

  @media print and (color) {
    @page {
      margin: 0.3in;
      size: landscape;
    }
  }
}
